import React from 'react';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';

const ContentBlock = ({ fields, titleTag = 'h2' }) => {
  return (
    <React.Fragment>
      <Text field={fields.title} tag={titleTag} />
      <span>
        <RichText field={fields.description} />
      </span>
    </React.Fragment>
  );
};

export default ContentBlock;
