import React from 'react';
import { DefaultProps, PropTypes } from '../../../core/Utils/SitecoreFields';
import ContentBlock from '../../../core/Components/ContentBlock';

const Disclaimer = ({ fields }) => (
  <div className="disclaimer">
    <div className="l-padding">
      <ContentBlock fields={fields} />
    </div>
  </div>
);

Disclaimer.propTypes = PropTypes.SitecoreItem.inject(PropTypes.ContentBlock);
Disclaimer.defaultProps = DefaultProps.SitecoreItem.inject(
  DefaultProps.ContentBlock,
);

export default Disclaimer;
